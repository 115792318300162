@import '../variables/variables.less';
@import '../style-guide/components/frequently-asked.less';
@import '../style-guide/components/platform-switcher.less';

body {
	color: #1C222E;
}

h1,
.h1 {
	font-size: 56px;
	font-weight: 900;
	line-height: 72px;

	@media @mobile, @tablet {
		font-size: 2.75rem;
		line-height: 3rem;
		font-weight: 750;
	}
}

h2,
.h2 {
	font-size: 46px;
	font-weight: 900;
	line-height: 60px;

	@media @mobile, @tablet {
		font-size: 2.125rem;
		line-height: 2.75rem;
		font-weight: 750;
	}
}

h6,
.h6 {
	font-size: 20px;
	font-weight: 900;
	line-height: 26px;
}

.h7 {
	font-size: 16px;
	font-weight: 900;
	line-height: 22px;
	text-align: left;
}

.body-1 {
	font-size: 20px;
	font-weight: 400;
	line-height: 26px;
}

.body-2 {
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
}

.body-3 {
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;

	@media @mobile, @tablet {
		text-align: left;
	}
}

.body-4 {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.body-5 {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.flex {
	display: flex;
}

.justify-content-center {
	justify-content: center;
}

.align-items-center {
	align-items: center;
}

.text-white {
	color: @White;
}

.text-center {
	text-align: center !important;
}

.text-left {
	text-align: left !important;

	@media @mobile, @tablet {
		text-align: center !important;
	}
}

.text-gray3 {
	color: #697284;
}

.bg-off-white {
	background-color: #F2F8FF;
}

.bg-white {
	background-color: @White;
}

.shadows {
	box-shadow: 0px 40px 56px 0px rgba(30, 34, 42, 0.08);
}

.border-10 {
	border-radius: 10px;
}

.border-gray5 {
	border: 1px solid #CED4E0;
}

.mb-32 {
	margin-bottom: 32px;
}

.px-20 {
	@media @mobile, @tablet {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.link-underline {
	font-size: 14px;
	font-weight: 700 !important;
	line-height: 16px;
	letter-spacing: 0.6px;
	text-align: left;
	border-bottom: 1px solid !important;

	&:hover {
		border-bottom: none !important;
	}
}

.row {
	&.reverse {
		@media @mobile {
			flex-direction: column-reverse;
		}
	}

	&::after {
		content: none;
	}
}

#top .top-hint a {
	color: @White;
	border-bottom: 1px solid @White;

	&:hover {
		border-bottom: none;
	}
}

.hero-headline {
	@media @mobile, @tablet {
		font-size: 2.75rem;
		line-height: 3rem;
		font-weight: 750;
	}
}

.bg-gray {
	background-color: #F2F8FF;
}

.bg-gray8 {
	background-color: #F7F9FC;
}

#top {
	background: url("../../i/page/free-antivirus-download/bg-hero-gloweb7524.jpg");
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: top;
	background-size: cover;
	background-position: 50% 50%;
	background-color: #2C2930;
	text-align: center;
	padding-top: 64px;
	color: @White;
	padding-bottom: 5%;

	@media @tablet {
		padding-bottom: 125px;
	}

	@media @mobile {
		padding-bottom: 264px;
	}

	.container {
		&:not(.full) {
			@media @desktop {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}

	.product-name {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: .5rem;
		margin-bottom: .5rem;
	}

	.button {
		margin-bottom: 0;

		&.primary {
			&.green {
				&.shadow {
					span {
						box-shadow: none;
					}
				}
			}
		}
	}

	h1 {
		color: @White;
		font-size: 56px;

		@media @mobile {
			font-size: 32px;
		}
	}

	.hero-headline {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 1.5rem;

		@media @mobile {
			display: block;
			margin-bottom: .5rem;
		}

		img {
			margin-right: 1.5rem;

			@media @mobile {
				margin-right: 0;
				margin-bottom: 1.5rem;
			}
		}

		h1 {
			margin: 0;
		}
	}


	.top-awards {
		margin-bottom: 48px;

		@media @mobile {
			margin-bottom: 30px;
		}

		&.span2 {
			padding-left: 24px;
			padding-right: 24px;
		}

		.top-awards-content {
			@media @mobile {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
			}

			img {
				margin-bottom: 28px;

				@media @mobile {
					margin-bottom: 0;
					margin-right: 26px;
				}
			}

			.top-awards-text {
				font-weight: 700;
				font-size: 20px;

				@media @mobile {
					font-size: 16px;
					text-align: left;
				}
			}

		}

		p {
			margin-bottom: 0;
		}
	}

	.top-hint {
		margin-top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1rem;

		@media @mobile, @tablet {
			flex-direction: column;
		}

		.top-hint-wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			justify-content: center;
			max-width: 537px;
			gap: 1rem;

			@media @mobile, @tablet {
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: 1rem;
			}

			@media @mobile {
				align-items: baseline;
				max-width: 160px;
			}

			.lng-de &, .lng-es &, .lng-pt &, .lng-nl &, .lng-pl &, .lng-it &, .lng-ja & {
				@media @desktop {
					max-width: 700px;
				}
			}
		}

		.top-hint-award {
			display: flex;
			gap: .5rem;

			@media @mobile {
				margin-bottom: 0;
				gap: .5rem;
			}

			.lng-de &, .lng-es &, .lng-pt &, .lng-nl &, .lng-pl &, .lng-it &, .lng-ja & {
				@media @desktop {
					width: 100%;
				}
			}

			&:first-child {
				@media @mobile {
					margin-left: 0;
				}

				.top-award-text {
					white-space: normal;
					width: max-content;
				}
			}
		}

		.top-hint-text {
			margin: 32px 0 64px;
		}

		.trustpilot {
			width: 45%;
			height: 20px;

			@media @mobile {
				margin: 0 0 24px 0;
				width: 100%;
				height: 60px;
			}

			.lng-de &, .lng-es &, .lng-pt &, .lng-nl &, .lng-pl &, .lng-it &, .lng-ja & {
				@media @desktop {
					min-width: 255px;
				}
			}
		}

		img {
			margin-right: 8px;
			object-fit: contain;

			@media @mobile {
				margin-right: 0;
				margin-left: 0;
			}

		}

		a {
			color: @White;
			border-bottom: 1px solid @White;
			;
			text-decoration: none;
		}

		p {
			margin-bottom: 0;
			color: @White-v2;

			@media @mobile {
				color: @Grey-8;
			}
		}

		.top-award-text {
			font-size: 12px;
			line-height: 18px;
			margin-bottom: 0;
			text-align: left;
			color: @White-v2;
		}
	}

	p {
		&.sub-h1 {
			margin-bottom: 40px;
			color: @White;
			max-width: 896px;
		}
	}

	.img {
		margin-top: 88px;

		@media @mobile {
			margin-top: 32px;
		}
	}
}

.hero-features-container {
	max-width: 100%;
	margin-top: -11vh;

	@media @tablet {
		margin-top: -18vh;
	}

	@media @mobile {
		margin-top: -32vh;
	}

	.ribbon-container {
		margin-bottom: -2.5vh;

		@media @mobile {
			margin-bottom: -2vh;
		}

		.ribbon-text {
			background-color: #D9E8FA;
			display: inline-block;
			padding: 13px 16px;
			font-size: 18px;
			font-weight: 700;
			line-height: 22px;
			letter-spacing: 1.6px;
			position: relative;

			@media @mobile {
				font-size: 16px;
				line-height: 20px;
				letter-spacing: 1.2px;
				padding: 10px 12px;
				text-align: center;
			}

			&::before {
				content: "";
				background-image: url("../../i/page/free-antivirus-download-t1/ribbon-left.svg");
				background-size: 14px 100%;
				position: absolute;
				top: 0;
				background-repeat: no-repeat;
				width: 14px;
				height: 100%;
				left: -13px;

				@media @mobile {
					width: 18px;
					background-size: 20px 100%;
					left: -15px;
				}
			}

			&::after {
				content: "";
				background-image: url("../../i/page/free-antivirus-download-t1/ribbon-right.svg");
				background-size: 14px 100%;
				position: absolute;
				top: 0;
				background-repeat: no-repeat;
				width: 14px;
				height: 100%;
				right: -13px;

				@media @mobile {
					width: 18px;
					background-size: 20px 100%;
				}
			}
		}
	}

	.hero-features {
		display: flex;
		gap: 32px;
		justify-content: space-between;
		max-width: 1360px;
		margin-left: auto;
		margin-right: auto;
		padding: 32px 80px;
		border: 1px solid #CED4E0;
		border-radius: 10px;
		box-shadow: 0px 16px 32px 0px rgba(30, 34, 42, 0.08);
		background-color: @White;
		color: #1C222E;

		@media @tablet {
			padding: 32px 24px;
			flex-wrap: wrap;
		}

		@media @mobile {
			flex-direction: column;;
			justify-content: center;
			align-items: center;
			gap: 24px;
			padding: 32px 24px;
		}

		.hero-feature {
			display: flex;
			align-items: center;
			text-transform: none;
			font-size: 18px;
			font-weight: 800;
			line-height: 22px;
			letter-spacing: 2.5px;
			text-align: left;
			max-width: 264px;

			@media @tablet {
				width: 300px;
				max-width: 300px;
				margin: auto;
			}
			@media @mobile {
				width: 264px;
				margin: auto;
			}


			img.hero-feature-icon {
				margin-right: 1rem;

				@media @tablet {
					margin-right: .5rem;
				}
			}

			.hero-feature-text {
				max-width: 200px;

				@media @mobile, @tablet {
					font-size: 16px;
					font-weight: 700;
					line-height: 20px;
					letter-spacing: 1.2px;
				}

				.lng-nl & {
					max-width: fit-content;
				}
			}
		}
	}
}

.section-antivirus {
	padding: 0;

	.container {
		padding-top: 56px;
		padding-bottom: 56px;

		@media @mobile {
			padding-top: 64px;
			padding-bottom: 64px;
		}

		.row {
			.image {
				padding: 36px 0;

				@media @mobile {
					padding: 0;
					margin: 0;
				}
			}

			.text {
				padding: 32px 0;

				@media @mobile {
					padding-top: 0;
				}
			}
		}
	}
}

//wtf
.top-hint-wrapper {
	width: 80%;
}

.top-awards {
	.top-award-text {
		width: 100%;
	}
}

#top .top-hint .top-hint-award {
	align-items: center;
}

//wtf end

/* Features */
.features {
	padding: 32px 0 88px 0;

	@media @mobile {
		padding: 24px 0 64px 0;
	}

	h2 {
		margin-bottom: 66px;
		text-align: center;

		@media @mobile {
			margin-bottom: 44px;
		}
	}

	h4 {
		margin-bottom: 16px;
		margin-top: 24px;

		@media @mobile {
			font-size: 16px;
			margin-top: 10px;
			padding-left: 16px;
			text-align: left;
		}
	}

	p {
		color: #697284;
		text-align: center;
		font-size: 14px;

		@media @mobile {
			font-size: 14px;
			padding-left: 16px;
		}
	}

	.row {
		gap: 2rem;

		&::after {
			content: none;
		}

		@media @tablet {
			flex-direction: column;
			width: 100%;
			margin: auto;
		}
	}

	.feature-tab {
		padding: 0;
		border-radius: 6px;

		@media @mobile {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

		}

		.feature-card {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			gap: 8px;

			@media @mobile {
				margin: 0;
				width: 100%;
			}

			.h7 {
				text-align: center;
			}

			p {
				margin-bottom: 0;

				.signal-link {
					color: #697284;
					text-align: center;
					font-size: 14px;
				}
			}
		}
	}
}

/* /Features */

.button.primary.green.shadow>SPAN {
	box-shadow: none;
}

/* Section antivirus */
.section-antivirus {

	h2 {
		@media @mobile {
			text-align: center;
		}
	}

	p {
		&.subh2 {
			text-align: left;

			@media @mobile {
				text-align: center;
			}
		}
	}

	.platforms {
		margin-bottom: 16px;

		@media @mobile {
			text-align: center;
		}

		p {
			@media @mobile {
				text-align: left;
			}
		}

		img {
			margin-right: 12px;
		}
	}

	.label {
		font-size: 13px;
		border-radius: 4px;
		margin-bottom: 8px;

		&.label-green {
			background-color: @GreenPrimary-v2;
		}
	}

	.text-bold-li {
		color: @Black-v2;
		font-weight: bold;
	}


	li {
		padding-bottom: 0;

		.list-item {
			p {
				margin-left: 48px;
			}

			&:before {
				position: relative;
				top: 45px;
				left: 0;
				content: "";
				display: inline-block;
				width: 24px;
				height: 24px;
				background: transparent url(../../i/page/free-antivirus-download/check-oval.svg) center center no-repeat;
			}
		}

		p {
			margin-bottom: 0;
		}
	}

	.image {
		@media @mobile {
			margin-top: 42px;
		}
	}

	.text {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		@media @mobile {
			align-items: center;
		}
	}
}

/* /Section antivirus */

/* Section protection */
.section-protection {
	text-align: center;
	color: @White;

	.container {
		background: @Black-v2;
		padding-top: 88px;
		padding-bottom: 88px;
		margin-top: 88px;

		@media @mobile {
			margin-top: 64px;
			padding-top: 70px;
			padding-bottom: 70px;
		}
	}

	p {
		color: @White-v2;

		@media @mobile {
			font-size: 14px;
			padding-left: 24px;
			text-align: left;
		}
	}

	.feature-tab {
		text-align: left;

		&.span2 {
			padding-right: 32px;
			padding-left: 32px;
		}

		@media @desktop {
			max-width: 200px;
		}

		@media @mobile {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;

			img {
				margin-top: 12px;
			}

		}

		.image {
			text-align: left;
		}
	}

	.product-title {
		display: flex;
		justify-content: center;
		flex-direction: row;
		align-items: center;
		color: @Blue-0;
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 22px;

		@media @mobile {
			p {
				padding-left: 0;
			}
		}

		p {
			margin-bottom: 0;
			color: @Blue-0;
		}

		img {
			margin-right: 12px;
		}
	}

	h2 {
		color: @White;
		text-align: center;
		margin-bottom: 48px;

		@media @mobile {
			margin-bottom: 40px;
			text-align: center;
		}
	}

	h4 {
		color: @White;
		margin-bottom: 16px;
		margin-top: 24px;

		@media @mobile {
			font-size: 16px;
			padding-left: 24px;
			text-align: left;
		}
	}

	.button {
		margin-top: 18px;
	}
}

/* /Section protection */

/* Awards-card */
#awards-card {
	.container {
		background: @White-v2;
		padding-top: 97px;
		padding-bottom: 104px;
		text-align: center;

		@media @mobile {
			padding-top: 70px;
			padding-bottom: 24px;
		}

		h2 {
			margin-bottom: 28px;
			margin-top: 32px;
		}

		p {
			margin-bottom: 48px;

			@media @mobile {
				margin-bottom: 0;
				font-size: 14px;
			}
		}
	}

	.card {
		text-align: center;

		@media @mobile {
			padding: 0 20px 40px 20px;
		}

		.d-sm-none {
			@media @mobile, @tablet {
				display: none;
			}
		}

		.img-award {
			margin: 0 96px;

			@media @mobile {
				margin: 0;
			}

			@media @tablet {
				margin: 0 48px;
			}
		}

		.awards-img {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-top: 0;

			@media @mobile {
				flex-direction: column;
				margin-top: 22px;
			}

			.awards-icon {
				img {
					@media @tablet, @desktop {
						height: 65px;
					}
				}

				@media @mobile {
					display: flex;
					flex-direction: row;
					align-items: center;
				}
			}

			p {
				font-size: 16px;
				font-weight: 700;
				color: @Black-v2;
				margin-bottom: 0;
				margin-top: 18px;

				@media @mobile {
					font-size: 14px;
					text-align: left;
					margin-left: 16px;
				}

				span {
					font-size: 11px;
					color: @Grey-2;
					font-weight: 400;

					@media @mobile {
						font-size: 10px;
					}
				}
			}

			img {
				@media @mobile {
					margin-top: 40px;
					width: 96px;
				}
			}
		}
	}
}

.awards-card {
	padding-top: 88px;
	padding-bottom: 88px;

	.awards-card-heading {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;

	}

	.card {
		.awards-img {
			display: flex;
			gap: 2rem;

			.awards-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				gap: .75rem;
			}
		}
	}
}
/* /Awards-card */

/* Rating */
.rating {
	.container {
		padding-top: 72px;
		padding-bottom: 96px;

		@media @mobile {
			padding-top: 64px;
			padding-bottom: 56px;
		}

		&.rating {
			@media @desktop {
				padding-bottom: 0;
			}

			@media @desktop-large {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.carousel-slider {

			.tns-nav {
				@media @desktop-large {
					display: none;
				}
			}
		}

		h2 {
			margin-bottom: 28px;
			text-align: center;

			@media @mobile {
				margin-bottom: 24px;
			}
		}

		.text {
			p {
				@media @desktop {
					padding-left: 140px;
					padding-right: 140px;
					margin-bottom: 0;
				}
			}
		}

		p {
			text-align: center;

			@media @mobile {
				margin-bottom: 0;
				font-size: 14px;
			}

			@media @tablet {
				margin-bottom: 0;
			}
		}

		.rating-boxes {
			@media @tablet {
				flex-direction: column;
			}

			p {
				text-align: left;
				margin-bottom: 34px;
				margin-top: 20px;
				font-weight: 400;

				@media @mobile {
					margin-bottom: 28px;
				}
			}

			.icon {
				margin-bottom: 36px;

				@media @mobile {
					margin-bottom: 28px;
				}
			}
		}

	}
}

.section-rating {
	padding-top: 88px;
	padding-bottom: 88px;

	@media @mobile {
		padding-top: 64px;
		padding-bottom: 64px;
	}

	.rating-boxes {
		gap: 2rem;

		@media @tablet {
			width: 100%;
			margin: auto;
			flex-direction: column;
		}

		.rating-card-wrapper {
			padding: 0;

			.rating-card {
				background-color: @White;
				border: 1px solid #CED4E0;
				border-radius: 10px;
				padding: 48px 40px;

				@media @mobile, @tablet {
					padding: 40px 32px;
					text-align: left;
				}

				img.icon-quote {
					filter: invert(41%) sepia(73%) saturate(3418%) hue-rotate(198deg) brightness(89%) contrast(87%);
					margin-bottom: 16px;
				}
			}
		}
	}


}

/* /Rating */

.section-media-2 {
	padding-top: 32px;
	padding-bottom: 32px;

	@media @mobile {
		padding: 64px 0;
	}
}

.section-feature-cards {
	padding-top: 32px;
	padding-bottom: 88px;

	.feature-cards-container {
		display: flex;
		flex-direction: column;
		gap: 2rem;

		.feature-cards-wrapper {
			display: flex;
			gap: 2rem;

			@media @tablet {
				width: 100%;
				margin: auto;
				flex-direction: column;
			}

			.feature-card {
				display: flex;
				gap: 1rem;
				padding: 24px 24px 32px;
				border: 1px solid #CED4E0;
				border-radius: 6px;

				@media @tablet {
					height: inherit;
				}

				@media only screen and (min-width: 1200px){
					height: 170px;
					min-height: 170px;
					width: 4442x;
					min-width: 442px;
				}

				.lng-de &, .lng-es &, .lng-pt &, .lng-nl &, .lng-pl &, .lng-fr &,
				.lng-it &, .lng-cs &, .lng-ru &, .lng-tr &, .lng-ms &, .lng-sk & {
					@media @desktop {
						height: 300px;
						min-height: 170px;
						width: 350px;
						min-width: 350px;
						max-width: 350px;
					}

					@media only screen and (min-width: 1200px){
						height: 250px;
						min-height: 250px;
						width: 350px;
						min-width: 350px;
					}

					@media only screen and (min-width: 1300px){
						height: 250px;
						min-height: 250px;
						width: 400px;
						min-width: 400px;
					}

					@media only screen and (min-width: 1400px){
						height: 250px;
						min-height: 250px;
						width: 442px;
						min-width: 442px;
					}
				}

				&-text {
					display: flex;
					flex-direction: column;
					gap: .5rem;

					div {
						color: #1C222E;

						&.body-4 {
							color: #697284;
						}
					}
				}
			}

		}
	}

	.span4 {
		margin-bottom: 0;
	}
}

.section-bft {
	padding-top: 88px;

	.row {
		&:first-child {
			margin-bottom: 88px;

			@media @mobile, @tablet {
				margin-bottom: 0;
			}
		}

		.product-title {
			display: flex;
			justify-content: center;
			gap: 1.5rem;

			@media @mobile {
				gap: .5rem;
			}
		}

		.subh2 {
			margin-bottom: 40px;
		}
	}

	.product-title {
		img {
			margin-right: 0;
		}
	}
}

/* System requirments */
.system-requirments {

	.container {
		padding-top: 56px;
		background: @White-v2;

		@media @mobile {
			padding-top: 32px;
		}

		h3 {
			margin-bottom: 32px;

			@media @mobile {
				font-size: 24px;
				text-align: left;
			}
		}

		a {
			text-decoration: underline;
		}

		p {
			margin-bottom: 0;

			@media @mobile {
				font-size: 14px;
				text-align: left;
			}
		}

		.box {
			border-bottom: 1px solid @Grey-12;
			padding-bottom: 32px;
			padding-top: 32px;

			ul {
				@media @mobile {
					text-align: left;
				}
			}

			&.box-4 {
				border-bottom: none;
			}

			li {
				padding-bottom: 8px;
				padding-inline-start: 0;

				@media @mobile, @tablet {
					font-size: 14px;
					padding-inline-start: 40px;
				}

				&:before {
					position: relative;
					margin-right: 18px;
					top: 6px;
					left: 0;
					content: "";
					display: inline-block;
					width: 24px;
					height: 24px;
					// background: transparent url(../../i/page/free-antivirus-download/icon-check-oval-green.svg) center center no-repeat;

					@media @mobile, @tablet {
						position: absolute;
						margin-right: 0;
						top: 0;
					}
				}

				&:nth-child(1)::before {
					background: transparent url("../../i/page/free-antivirus-download-t1/number-1.png");
				}

				&:nth-child(2)::before {
					background: transparent url("../../i/page/free-antivirus-download-t1/number-2.png");
				}

				&:nth-child(3)::before {
					background: transparent url("../../i/page/free-antivirus-download-t1/number-3.png");
				}

				&:nth-child(4)::before {
					background: transparent url("../../i/page/free-antivirus-download-t1/number-4.png");
				}

			}

			.platform {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				@media @mobile {
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
				}

				img {
					margin-bottom: 18px;
				}

				.platform-mobile {
					@media @mobile {
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: flex-start;

						&.ios {
							align-items: center;

							img {
								margin-bottom: 0;
							}
						}

						img {
							margin-right: 16px;
						}
					}
				}
			}
		}
	}
}

/* /System requirments */

/* Faq */
.faq-container {
	position: relative;
	z-index: 10;
	box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
	background-color: @White;
	padding-top: 15px;
	padding-bottom: 15px;

	>.accordion-item {
		&:not(:first-child) {
			border-top: 1px solid @Grey-5;
		}
	}

	// question
	.question {
		position: relative;
		cursor: pointer;

		@media @tablet, @desktop {
			padding: 39px 128px 39px 48px;
		}

		@media @mobile {
			padding: 20px 63px 20px 24px;
		}

		// clicker
		&::after {
			position: absolute;
			content: "";
			display: inline-block;
			border-radius: 5px;
			border: 1px solid @Grey-4;
			background-color: transparent;
			background-position: center center;
			background-repeat: no-repeat;
			background-image: url(../../i/ico/ico-plus-blue.svg);

			@media @tablet, @desktop {
				top: 24px;
				right: 32px;
				width: 56px;
				height: 56px;
			}

			@media @mobile {
				top: 16px;
				right: 16px;
				width: 32px;
				height: 32px;
			}
		}

		&:hover:not(.expand)::after {
			background-color: @Green;
			border: none;
			background-image: url(../../i/ico/ico-plus-white.svg);
		}

		&.expand::after {
			background-color: transparent;
			background-image: url(../../i/ico/ico-close-blue.svg);
		}

		&.expand:hover::after {
			background-color: @Green;
			border: none;
			background-image: url(../../i/ico/ico-close-white.svg);
		}
		&.expand {
			h4 {
				color: #2276D9;
			}
		}
	}

	// answer
	.answer {
		overflow: hidden;
		transition: max-height 0.3s ease-out; // note that we're transitioning max-height
		height: auto;
		max-height: 0;
		overflow: hidden;

		@media @tablet, @desktop {
			padding: 0 128px 0 80px;
		}

		@media @mobile {
			padding: 0 64px 0 24px;
		}


		h5,
		li,
		p {
			@media @mobile {
				font-size: 12px;
				line-height: 18px;
			}
		}

		h5 {
			margin-bottom: 0;
		}

		ol {
			padding-inline-start: 40px;
		}

		ul {
			li {
				padding-bottom: 0;
				padding-left: 40px;
				position: relative;

				&:before {
					content: '·';
					display: inline-block;
					width: auto;
					height: auto;
					color: @Green;
					position: absolute;
					left: 20px;
					font-size: 2.5rem;
					line-height: 23px;

					@media @mobile {
						line-height: 17px;
					}
				}
			}
		}

		p:last-child {
			@media @tablet, @desktop {
				margin-bottom: 39px;
			}

			@media @mobile {
				margin-bottom: 16px;
			}
		}
		.signal-link {
			color: #2276D9;
			text-align: center;
			font-size: 16px;

			@media @mobile {
				font-size: 12px;
			}
		}
	}

	// expand
	div.expand+div {
		max-height: 600px;
	}
}

#faq {

	.container {
		padding-top: 88px;
		padding-bottom: 88px;
		background: #F8F9FA;

		@media @mobile {
			padding-top: 32px;
			padding-bottom: 64px;
		}
	}

	h2 {
		text-align: center;
		margin-bottom: 64px;
	}

	h4 {
		@media @mobile {
			font-size: 16px;
		}
	}
}

/* /Faq */

/* blog-posts */
#blogposts {
	padding-top: 88px;
	padding-bottom: 64px;

	@media @mobile {
		padding-top: 64px;
	}


	.button.secondary>span {
		font-weight: 500;
	}

	.container {
		//overflow-x: hidden;
		padding-bottom: 56px;

		@media @mobile {
			padding-bottom: 48px;
		}
	}


	.post {
		@media @mobile {
			margin-bottom: 24px;
		}
	}

	.row {
		&.blog {
			justify-content: center;
		}
	}


	.carousel-slider {
		.container {
			@media @mobile, @tablet {
				padding-top: 0;
			}
		}
	}

	h2 {
		text-align: left;
		margin-bottom: 0;

		@media @mobile {
			margin-bottom: 24px;
		}
	}

	h4 {
		margin-bottom: 16px;
		padding-bottom: 16px;
		border-bottom: 1px solid @Grey-12;

		@media @mobile, @tablet {
			font-size: 16px;
		}
	}

	img {
		width: 100%;

		@media @mobile, @tablet {
			margin-bottom: 16px;
		}
	}

	p {
		color: @Grey-1;
		text-decoration: none;
		font-size: 14px;
	}

	a {
		&:hover {
			text-decoration: none;
		}

		&.button {
			&.secondary {
				span {
					border-bottom: 2px dashed @Blue-v2;
				}
			}
		}
	}

	.link-all {
		margin-bottom: 0;

		@media @tablet, @desktop {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			padding-bottom: 16px;
		}

		a {
			border-bottom: none;

			&.button {
				display: flex;
				justify-content: flex-end;

				@media @mobile {
					justify-content: flex-start;
				}
			}
		}
	}
}

/* /blog-posts */

/* Bottom-section */
.bottom-section {

	.container {
		text-align: center;
		padding-top: 70px;

		h2 {
			margin-bottom: 0;
		}

		p {
			margin-bottom: 48px;
			margin-top: 28px;
		}

		.img {
			margin-top: 48px;
		}

		.product-title {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			img {
				margin-right: 16px;
			}
		}

		.button {
			&.primary {
				&.green {
					span {
						&.text-large {
							font-size: 20px;

							@media @mobile {
								font-size: 16px;
							}
						}
					}
				}
			}
		}

	}

}

/* /Bottom-section */

// AB test GLOWEB-7071
#free-antivirus-download-t2 {
	.hero-headline {
		margin-left: -15px;
		margin-right: -15px;
	}
}

.media-object {
	.image {
		@media @mobile {
			display: none;
		}
	}

	.text {
		.image-mobile {
			display: none;

			@media @mobile {
				display: block;
				margin: 2rem auto;
			}
		}

		h2 {
			@media @mobile {
				margin-bottom: 0;
			}
		}
	}
}

#bottom {
	.row {
		@media @tablet {
			flex-direction: column;
			align-items: center;
		}

		.footer-links {
			@media @tablet {
				width: 100%;
				text-align: center;
			}
		}

		.social-connect {
			@media @tablet {
				border-left: none;
			}
		}
	}
}